.App {
  text-align: center;
  background-color: #fff;
  min-height: 100vh;
}

.am-navbar-light{
  background-color: #FF4000 !important;
}
.am-navbar-light .am-navbar-title{
  color: #fff !important;
}
.App-header {
  position: fixed;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-logo{
    width: 140px;
    height: auto;
  margin-bottom: 40px;
}
.am-button-primary{
  background-color: #FF4000 !important;
  border: none !important;
}
.am-button-small{
    height: 40px;
    line-height: 40px;
    padding: 0 40px;
}
.bgimg{
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bgimg img{
  width: 100%;
  height: 100%;
}
.apk-introduction{
    margin-top: 70px;
    padding: 0 20px;
    color: #757575;
    font-size: 14px;
    text-align: justify;
    line-height: 20px;
}
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx){
  html:not([data-scale]) .am-button-primary::before{
    border: none !important;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
